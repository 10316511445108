<template>
    <default-app-template>
        <div data-cypress="dataUsage">
            <basic-headline>Data Usage Agreement</basic-headline>

            <div
                class="data-usage-agreement bg-gray-200 overflow-scroll mx-auto w-4/5 h-128 mb-5 rounded-lg"
            >
                <div class="content p-5" v-html="dataUsageAgreement.text"></div>
            </div>

            <div class="flex justify-end  mx-auto w-4/5">
                <basic-button
                    icon-left
                    icon="times"
                    color="gray"
                    class="mr-5"
                    data-cypress="dataUsage.button.cancel"
                    @click="disagree()"
                    >Disagree</basic-button
                >
                <basic-button
                    icon="check"
                    icon-right
                    data-cypress="dataUsage.button.submit"
                    @click.prevent="agree()"
                >
                    Agree
                </basic-button>
            </div>
        </div>
    </default-app-template>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dataUsageAgreement } from 'helpers/constants'

export default {
    data() {
        return {
            validation: {},
        }
    },
    computed: {
        dataUsageAgreement() {
            return {
                version: 1,
                text: dataUsageAgreement,
            }
        },
        ...mapState('measurement', {
            measurement: state => state.active,
        }),
    },

    mounted() {
        this.new()
    },
    methods: {
        agree() {
            // Save Agreement
            let agreementData = {
                dataUsageAgreement: {
                    timestamp: Date.now(),
                    version: this.dataUsageAgreement.version,
                },
            }

            this.addPatientData(agreementData)

            // Go to Named Route
            this.$router.push({
                name: 'PatientDataCollection',
                params: { uuid: this.measurement.uuid },
            })
        },

        disagree() {
            // Go to Named Route
            this.$router.push({
                name: 'Dashboard',
            })
        },
        // VUEX
        ...mapActions('measurement', [
            'new', // -> this.new()
        ]),
        ...mapActions('patient', ['addPatientData']),
    },
}
</script>
